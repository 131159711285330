import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash.get"

import getExcerptOfBody from "../utils/getExcerpt"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "../components/grid"
import Breadcrumbs from "../components/breadcrumbs"
import PostBody from "../components/post-body"
import Share from "../components/share"
import Seperator from "../components/seperator"
import PostPagination from "../components/post-pagination"

export const query = graphql`
  query BlogPostBySlug($uid: String!, $prev: String, $next: String) {
    prismicPost(uid: { eq: $uid }) {
      id
      uid
      type
      data {
        image {
          alt
          copyright
          gatsbyImageData(layout: CONSTRAINED, width: 1032)
        }
        title
        date
        body {
          ... on PrismicPostDataBodyText {
            id
            slice_type
            slice_label
            primary {
              text {
                text
                richText
              }
            }
          }
          ... on PrismicPostDataBodyQuote {
            id
            slice_type
            slice_label
            primary {
              quote {
                text
                richText
              }
              author {
                text
                richText
              }
            }
          }
          ... on PrismicPostDataBodyImage {
            id
            slice_type
            slice_label
            primary {
              caption {
                text
                richText
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 856)
              }
            }
          }
          ... on PrismicPostDataBodyGallery {
            id
            slice_type
            slice_label
            items {
              caption {
                text
                richText
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 1024)
              }
            }
          }
        }
      }
    }
    prevPost: prismicPost(uid: { eq: $prev }) {
      uid
      lang
      type
      data {
        title
      }
    }
    nextPost: prismicPost(uid: { eq: $next }) {
      uid
      lang
      type
      data {
        title
      }
    }
  }
`

export default function Post({ location, data }) {
  const post = get(data, `prismicPost`)
  const prevPost = get(data, `prevPost`)
  const nextPost = get(data, `nextPost`)
  if (!post) return null

  return (
    <Layout location={location}>
      <SEO
        title={post.data.title}
        description={getExcerptOfBody(post.data.body)}
        image={post.data.image}
      />

      <Grid>
        <Breadcrumbs>
          <Link to="/">Home</Link>
          <Link to="/news">News</Link>
          <span>{post.data.title}</span>
        </Breadcrumbs>
        <PostBody post={post} />
        <Share
          location={location}
          image={post.data.image}
          description={getExcerptOfBody(post.data.body)}
        />
        <Seperator />
        <PostPagination prevArticle={prevPost} nextArticle={nextPost} />
      </Grid>
    </Layout>
  )
}
