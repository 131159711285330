import React from "react"
import { Link } from "gatsby"
import { linkResolver } from "../utils/linkResolver"
import { Container, List, Prev, Next, Label } from "./post-pagination.styles"
import Text from "./text"
import ArrowIcon from "./icons/arrow-icon"

export default ({ prevArticle, nextArticle }) => (
  <Container>
    <List>
      {prevArticle && (
        <Prev>
          <Link
            to={linkResolver(prevArticle)}
            aria-label="Prev Post"
            rel="prev"
          >
            <Label>
              <ArrowIcon rotate={180} size={24} /> <span>Previous Post</span>
            </Label>
            <Text.H3 as="span" color="black.4" fontWeight={7}>
              {prevArticle.data.title}
            </Text.H3>
          </Link>
        </Prev>
      )}
      {nextArticle && (
        <Next>
          <Link
            to={linkResolver(nextArticle)}
            aria-label="Next Post"
            rel="next"
          >
            <Label>
              <span>Next Post</span> <ArrowIcon size={24} />
            </Label>
            <Text.H3 as="span" color="black.4" fontWeight={7}>
              {nextArticle.data.title}
            </Text.H3>
          </Link>
        </Next>
      )}
    </List>
  </Container>
)
