import styled from "styled-components"
import { compose, grid, space, layout, flexbox } from "styled-system"
import Text from "./text"

export const Container = styled.nav`
  ${space}
  grid-column: main-start / main-end;
`

Container.defaultProps = {
  my: ["y.mobile.3.0", "y.desktop.3.0"],
}

export const List = styled.ul(compose(grid, space, layout, flexbox), {
  display: "grid",
})

List.defaultProps = {
  gridTemplateColumns: "1fr 1fr",
  gridTemplateAreas: [
    `"left left" "right right"`,
    `"left left" "right right"`,
    `"left right"`,
  ],
  gridColumnGap: ["1.6rem", "2.4rem", "2.4rem"],
}

export const Prev = styled.li`
  grid-area: left;
  justify-self: start;
  text-align: left;
`

export const Next = styled.li`
  grid-area: right;
  justify-self: end;
  text-align: right;
`

export const Label = styled(Text.Label)`
  > * {
    vertical-align: middle;
  }
`
