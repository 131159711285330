import styled from "styled-components"
import { color, border } from "styled-system"

export const Container = styled.hr`
  ${color}
  ${border}
  grid-column: full-start / full-end;
  width: 100%;
`

Container.defaultProps = {
  border: "none",
  borderTop: "1px solid",
  borderColor: "black.2",
}
